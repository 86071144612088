const takeADate = (arg) => {
    let a, b, c;
    if (new Date(arg).getDate() < 10) {
        a = '0' + new Date(arg).getDate()
    } else {
        a = new Date(arg).getDate()
    }
    if (new Date(arg).getMonth() <= 9) {
        b = '0' + (new Date(arg).getMonth() + 1)
    } else {
        b = new Date(arg).getMonth() + 1
    }
    c = new Date(arg).getFullYear();
    return [a, b, c].join('.')
};
export default takeADate;

