import './App.css';
import React, {useEffect, useState} from "react";
import takeADate from "./js/takeADate";
import env from "react-dotenv";


function App() {

    const [topScore, setTopScore] = useState('');
    const [topScoreName, setTopScoreName] = useState('');
    const [topScoreDay, setTopScoreDay] = useState('');
    const [topScoreDayName, setTopScoreDayName] = useState('');
    const [personsTopScore, setPersonsTopScore] = useState(0);
    const [personsName, setPersonsName] = useState(false);
    const [clickButton, setClickButton] = useState(true);
    const [click, setClick] = useState(0);
    const inText = 'Сколько раз успеешь кликнуть за 5 секунд?';
    const nextChance = 'Соберись !!!';
    const [startText, setStartText] = useState(inText);
    const [timer, setTimer] = useState(true);
    const [time, setTime] = useState(false);
    const [endText, setEndText] = useState('');
    const [startClick, setStartClick] = useState(false);
    const [endClick, setEndClick] = useState(false);
    const timeout = 5000;
    const [sec, setSec] = useState(timeout);
    let currentTime;
    const [endT, setEndT] = useState(false);
    const [tempName, setTempName] = useState('');
    const [gameOver, setGameOver] = useState(false);
    let tempData = [];
    const [allScore, setAllScore] = useState(null);
    const [showAllScore, setShowAllScore] = useState(false);
    const [ticket, setTicket] = useState('');

    function nameTr(arg) {
        if (!arg) return 'Инкогнито';
        return arg[0].toUpperCase() + arg.slice(1);
    }

    function formatTime(ms) {
        return (ms / 1000).toFixed(2);
    }

    function startFunc() {
        const formData = new FormData();
        formData.append('getData', env.REACT_APP_SECRET_KEY);
        formData.append('date', takeADate(Date.now()));
        fetch(env.REACT_APP_SECRET_PATH, {
            method: 'POST',
            body: formData,
        })
            .then(res => res.json())
            .then(res => {
                if (JSON.stringify(res) !== JSON.stringify(tempData)) {
                    console.log('fetch');
                    console.log(res);
                    setAllScore(res);
                    setTopScore(Number(res[0].score));
                    setTopScoreName(res[0].name);
                    if (res[1]) {
                        setTopScoreDay(Number(res[1].score));
                        setTopScoreDayName(res[1].name);
                    } else {
                        setTopScoreDay(0);
                        setTopScoreDayName(false);
                    }
                    tempData = res
                }
            })
            .catch(e => console.log(e));
    }

    function zxz() {
        return setInterval(() => {
            startFunc()
        }, 5000)
    }

    useEffect(() => {
        // zxz()
    }, []);

    useEffect(() => {
        if (endT) end();
    }, [endT]);

    const counter = () => {
        if (gameOver) setGameOver(false);
        else if (!personsName) {
            getToken();
            setPersonsName(nameTr(tempName.trim()));
            zxz();
            startFunc();
        } else if (personsName) {
            setClick(click + 1);
            if (!time) {
                setStartClick(true);
                currentTime = Date.now();
                const startTime = Date.now();
                const tInterval = setInterval(() => {
                    const delta = Date.now() - startTime;
                    setSec(timeout - delta);
                }, 100);
                const tout = setTimeout(() => {
                    // end();
                    setEndT(true);
                    clearTimeout(tout);
                    clearInterval(tInterval);
                }, timeout)
            }
            setTime(true);
        }
    };

    const start = () => {
        setClickButton(true);
        setClick(0);
        setStartText(nextChance);
        setStartClick(false);
        setEndClick(false);
        setTimer(true);
        setSec(timeout);
    };

    const end = () => {
        setEndT(false);
        setTime(false);
        setClickButton(false);
        setEndClick(true);
        setTimer(false);
        if (click > personsTopScore) {
            if (click > topScore) {
                setEndText('хм... да этож РЕКОРД');
                setPersonsTopScore(click);
                sendData(env.REACT_APP_SECRET_TOPKEY);
            } else if (click > topScoreDay) {
                setEndText('хм... да этож РЕКОРД ДНЯ');
                setPersonsTopScore(click);
                sendData(env.REACT_APP_SECRET_DAYKEY);
            } else if (click === topScoreDay) {
                setEndText('Почти обогнал');
                setPersonsTopScore(click);
            } else {
                setEndText('НОВЫЙ РЕЗУЛЬТАТ');
                setPersonsTopScore(click);
                sendData(env.REACT_APP_SECRET_SOMEKEY);
            }
        } else if (click === personsTopScore) {
            setEndText('Поднажми');
        } else {
            setEndText('Mаловато');
        }
        // console.log([takeAHour(currentTime), takeADate(currentTime)].join(' '));
        // console.log([takeAHour(Date.now()), takeADate(Date.now())].join(' '));
        // console.log(click)
    };

    function sendData(arg) {
        const formData = new FormData();
        formData.append('setDayData', arg);
        formData.append('score', click);
        formData.append('name', personsName);
        formData.append('date', takeADate(Date.now()));
        fetch(env.REACT_APP_SECRET_PATH, {
            method: 'POST',
            body: formData,
        })
    }

    function allsc() {
        setShowAllScore(!showAllScore)
    }

    function getToken() {
        // return 44
        const formData = new FormData();
        formData.append('getTicket', env.REACT_APP_SECRET_SOMEKEY);
        fetch(env.REACT_APP_SECRET_PATH, {
            method: 'POST',
            body: formData,
        })
            .then(res => res.json())
            .then(res => setTicket(res))
    }

    useEffect(() => {
        console.log(ticket)
    }, [ticket])
    return (
        <div className="Clicker">
            {personsName && !gameOver &&
            <div className="score">
                <div className="score-blok">
                    <div className="you"><span>{personsName}</span><span>max : {personsTopScore}</span></div>
                    {!time && <div className="all-button" onClick={allsc}>
                        {!showAllScore
                            ? 'Глянуть всех'
                            : 'Харош'
                        }
                    </div>
                    }
                </div>
                <div className="score-blok">
                    <div className="topScore"><span>- топ - </span><span>{topScoreName} : {topScore}</span></div>
                    {topScoreDay !== 0 &&
                    <div className="topDay"><span>- топ дня - </span><span>{topScoreDayName} : {topScoreDay}</span>
                    </div>
                    }
                </div>

            </div>
            }
            {!showAllScore &&
            <div className="click">
                {personsName && !gameOver
                    ? <div className="countInfo">
                        {timer && <div className="timer">{formatTime(sec)}</div>}
                        {!startClick && <div className="text">{startText}</div>}
                        {startClick && !gameOver && <div className="count">- {click} -</div>}
                    </div>
                    : !gameOver ?
                        <div>
                            <div className="yn">Ваше имя</div>
                            <input type="text"
                                   className="startInput"
                                   value={tempName}
                                // placeholder="Ваше имя"
                                   autoFocus
                                   onInput={(e) => setTempName(e.target.value)}
                            />
                        </div>

                        : <span className="go">GAME OVER</span>
                }
                {clickButton || gameOver
                    ?
                    <div className="b end clck" onClick={counter}>
                        {personsName
                            ? !gameOver ? 'Click' : 'Жми !!!'
                            : 'Пооехали'
                        }
                    </div>
                    :
                    endClick && !gameOver && <div className="endText">{endText}</div>
                }
            </div>
            }
            {endClick && !gameOver && !showAllScore &&
            <div className="buttons">
                <div className="b next" onClick={start}>ЕШШО РАЗОК</div>
                <div className="b end" onClick={() => setGameOver(true)}>НАДОЕЛО</div>
            </div>
            }
            {showAllScore &&
            <div className="showAllScore">
                <div className="shsr">
                    <div className="plase">МЕСТО</div>
                    <div className="winName">ИМЯ</div>
                    <div className="winScore">ОЧКИ</div>
                </div>
                {showAllScore &&
                allScore.sort((a, b) => b.score - a.score).map((item, i) => {
                    return <div key={i} className="shsr">
                        <div className="plase">{i + 1}</div>
                        <div className="winName">{item.name}</div>
                        <div className="winScore">{item.score}</div>
                    </div>
                })
                }
            </div>
            }
        </div>
    );
}

export default App;
